#reviews-page {
  margin-top: 101px;
}

.star_reviews {
  position: relative;
  display: inline-block;
  float: left;

  &:before {
    font-size: 1.5em;
    line-height: 1.5em;
    display:block;
    top:0;
    left:0;
    font-family: FontAwesome;
    content: "\f005\f005\f005\f005\f005";
    color: #818181;
    /*text-shadow: 0 2px darken(#818181,20%);*/
  }

  span {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    display:block;
    overflow:hidden;

    &:before {
      font-size: 1.5em;
      line-height: 1.5em;
      font-family: FontAwesome;
      color: #ffbb00;
      position: absolute;
      left: 0;
      top:0;
      content:"\f005\f005\f005\f005\f005";
      text-shadow: 0 1px darken(#ffbb00, 20%);
      display:block;
    }
  }
}

.text {
  display: inline-block;
  line-height: 36px;
  padding: 0 10px;
  padding-right: 5px;
  font-weight: 900;
}

label.rating {
  line-height: 36px;
  display: inline-block;
  margin: 0;
  font-size: 1.0em;
}

.checkboxLabel {
  line-height: 22px;

  .icheckbox_square-orange {
    margin-right: 10px;
  }
}


.reviews {
  padding: 0 !important;

  h5 {
    float: left;
    line-height: 27px;
    padding: 0 5px;
    font-size: 0.9em;
  }

  .text {
    line-height: 27px;
  }

  span {
    padding: 0 !important;
    float: none;
    font-size: 1.0em;
  }

  .star_reviews {

    &:before {
      font-size: 1.0em;
      line-height: 27px;
    }

    span {

      &:before {
        font-size: 1.0em;
        line-height: 27px;
      }
    }
  }

  .vote_numbers{
    margin-right: 5px;
    font-size: 0.9em;
}

}
