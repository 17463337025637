#faq-holder {
    height: 75vh;
    width: 70%;
    @media only screen and (max-width: 992px) {
        width: 100%;
        height: 80vh;
        margin: 100px auto 0 auto;
        padding-bottom: 50px;
    }
    margin: 100px auto 50px auto;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px 10px;
    h1 {
        margin: 0 0 20px 0;
        text-transform: uppercase;
        font-weight: 800;
        border-bottom: 5px solid $orange_color;
        padding-bottom: 12px;
        @media only screen and (max-width: 992px) {
            font-size: 60%;
        }
    }

    .hilight {
        color: $orange_color;
    }

    .row {
        width: 100%;
        clear: both;
        height: 100%;
        overflow: hidden;
        padding-bottom: 40px;
        @media only screen and (max-width: 992px) {
            overflow-y: visible;
        }

        .col-left {
            width: 30%;
            float: left;
            @media only screen and (max-width: 992px) {
                width: 100%;
            }
        }

        .col-right {
            width: 70%;
            float: right;
            overflow-y: scroll;
            position: relative;
            height: 100%;
            @media only screen and (max-width: 992px) {
                width: 100%;
                overflow-y: visible;
            }
        }

    }

    h1.title {
        font-size: 2em;
        text-align: center;
    }

    .faq-menu {
        cursor: pointer;
        .faq-menu-item {
            font-weight: 700;
            margin: 10px 0;
            &:hover {
                color: $light_orange_color;
            }
        }
        .faq-submenu {
            padding-left: 10px;
            display: none;
            .faq-submenu-item {
                margin: 10px 0;
                &:hover {
                    color: $light_orange_color;
                }
            }
        }
    }

    .faq-q {
        font-style: italic;
        font-weight: 700;
        margin: 8px 0;
    }

    .faq-a {
        margin-bottom: 10px;
        p {
            line-height: 160%;
        }
    }

    .faq-item {
        display: none;
    }

}   