/* compileCompressed: ../../../public/assets/css/style.min.css*/
/*

To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
Created on : 2015-12-29, 14:19:13
Author     : Frausiol
*/
/* IMPORT CONSTANTS FILE */
@import "_constans.scss";
@import "_reset.min.css";

/* END import constans file*/
@include scrollbars(.5em, #222d32);
body {
  font-family: $standard_font_family;
  overflow-y: scroll;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.margin-bottom {
  margin-bottom: 15px !important;
}

.no-margin-top {
  margin-top: 0px !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.no-margin-left {
  margin-left: 0px !important;
}

.no-padding-left {
  @extend .no-left-padding;
}

.no-padding-right {
  @extend .no-right-padding;
}

.no-padding-side {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* .overfow-hidden {*/
/*     overflow: hidden;*/
/* }*/

button[readonly], html input[readonly] {
  cursor: not-allowed;
}

input[readonly] {
  background-color: rgb(235, 235, 228);
}

input[readonly].hidden-value {
  color: #ebebe4;
}

html .timepicker[readonly] {
  cursor: default;
}

.timepicker[readonly] {
  background-color: #fff;
}

.required {
  font-weight: 600;
}

.fa-1-6x {
  font-size: 1.6em;
}

.lang_label {
  white-space: nowrap;
  overflow: hidden;
}

.table {
  font-size: 0.8em;

  td, th {
    vertical-align: middle !important;
    border: 1px solid #666 !important;
    text-align: center;
    padding: 0 !important;
  }
}

.table-input {
  width: 100%;
  height: calc(100% + 4px);
  text-align: center;
}

.table-button {
  width: 100%;
  margin: 0;
  padding: 5px;
}

#map {
  height: 470px;
  z-index: 10;
}

.courses-class {
  display: block;
  text-align: right;
  padding-right: 10px;
}

/* .courses-table-box {*/
/*   overflow-x: auto;*/
/* }*/
.courses-button-col {
  height: 100%;
  padding: 0;
  margin: 0;
}

.courses-button-col button,
.repeat_course_action button.single {
  padding: 0 10px !important;
  height: 100%;
}

.breadcrumb {

  .active {

    color: $orange_color;

  }

}

.width_100_p {
  width: 100%;
}

/* PANEL */
/*
HEADER
*/
.main-header {

  margin: 0;

  .logo-lg {
    margin: 5px 0;
    padding: 0;
    display: block;
    position: relative;
    z-index: 1;
    width: 170px;
    height: 40px;
    float: left;

    img {
      width: 100%;
      height: auto;
    }

  }

  .logo-mini {
    max-width: 50px;
    height: 50px;
    img {
      width: 90%;
      margin: 5%;
    }
  }

  .slogan {

    max-height: 32px;
    float: left;
    margin-top: 11px;
    margin-left: 12px;

  }

  .sidebar-toggle {
    padding: 17px 15px;
  }

  .nav {

    li {

      a {

        font-size: .8em;

      }

    }

  }

}

/* SIDEBAR PANEL */
.main-sidebar {

  .sidebar-menu {

    li {

      a {

        font-size: .8em;

      }

    }

  }

}

/* FILTERS AND PAGINATION */
.filters-box {

  .box {
    border: 0;
    border-bottom: 1px solid rgba($grey_color, .3);
  }

  .box-header {

  }

  .default_grey_button {
    margin: 0;
    padding: 9px;
  }

  .paginations {

    padding: 3px 0;

    .pagination {
      margin: 0 6px;
      border-radius: 0;
      background: $grey_color;
      cursor: pointer;

      &.current {
        background: $orange_color;
      }

      a {
        padding: 6px 9px;
        color: #fff;
        display: block;
      }

    }

  }

}

.callout-own {
  border-left: 5px solid #222d32;
  background: #ddd;
  text-align: center;

  p {
    font-size: 1.25em;
    padding-top: 5px;
  }

  a {
    text-decoration: none;
  }
}

.skin-blue .sidebar-menu > li:hover > a,
.skin-blue .sidebar-menu > li.active > a {
  border-left-color: $light_orange_color;
}

.additional-info {

  max-height: 150px;
  overflow-y: auto;
  width: 100%;

  .additional-photo {
    height: 22px;
    display: inline;
    line-height: 22px;
    vertical-align: middle;
    margin: 5px;
  }

  .additional-text {
    line-height: 20px;
    display: inline;
    vertical-align: middle;
    /*padding-left: 15px;*/

    span {
      vertical-align: middle;
      display: inline-block;
      max-width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      -webkit-transition: all .5s;
      transition: all .5s;
    }
  }

}

@media (max-width: 1480px) {
  .additional-info {
    .col-lg-4 {
      width: 50%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1090px) {
  .additional-info.additional-info-add-form {
    .col-lg-4 {
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .additional-info {
    .col-xs-12 {
      width: 100%;
    }
    .additional-text {

      span {

        max-width: 70%;

      }
    }
  }
}

.widthTransition {
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.reservations {
  background-color: $orange_color !important;
  border-color: $orange_color !important;
  color: #ffffff !important;

  &.active-event {
    background-color: $light_orange_color !important;
    color: #fff !important;
    border-color: $light_orange_color !important;
  }
}

.reservations:hover {
  background-color: $light_orange_color !important;
  color: #fff !important;
  border-color: $light_orange_color !important;
}

.user-event {
  background-color: $grey_color !important;
  border-color: $grey_color !important;
  color: #fff !important;

  &.active-event {
    background-color: $light_grey_color !important;
    color: #fff !important;
    border-color: $light_grey_color !important;
  }
}

.user-event:hover {
  background-color: $light_grey_color !important;
  color: #fff !important;
  border-color: $light_grey_color !important;
}

/*
PANEL
*/
.content-wrapper, .right-side {
  background-color: #fff;
}

.skin-blue .main-header .navbar,
.skin-blue .main-header .logo {
  background-color: #fff;
  box-shadow: 10px 2px 5px rgba(0, 0, 0, .3);
}

.skin-blue .main-header .logo {

  &:hover {
    background-color: white;
  }

}

.skin-blue .main-header .navbar .nav > li:first-child > a {
  font-weight: 600;
  color: $light_orange_color;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    color: $orange_color;
  }
}

.skin-blue .main-header .navbar .nav > li > a {
  background-color: $orange_color;
}

.skin-blue .main-header .navbar .nav > li > a:hover,
.skin-blue .main-header .navbar .nav > li > a:active,
.skin-blue .main-header .navbar .nav > li > a:focus,
.skin-blue .main-header .navbar .nav .open > a,
.skin-blue .main-header .navbar .nav .open > a:hover,
.skin-blue .main-header .navbar .nav .open > a:focus,
.skin-blue .main-header .navbar .nav > .active > a {
  background: $light_orange_color;
}

.skin-blue .main-header .navbar .sidebar-toggle {
  color: $light_grey_color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: $light_grey_color;
  color: #fff;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.nav-dashboard {

  .choose_language_container {

    .choose_language {
      background-color: #eee !important;

      -webkit-transition: all .5s;

      transition: all .5s;

      &:hover {
        background-color: #ddd !important;
      }
    }

    ul {
      background-color: #eee !important;
      border-radius: 0 !important;
      padding: 10px 0;
      width: 200px;
      box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.3), -1px 2px 2px 0px rgba(0, 0, 0, 0.3);
      /*max-height: 86px;*/
      /*overflow: hidden;*/
      /*overflow-y: scroll;*/

      p {
        font-weight: 600;
        padding-bottom: 15px;
        text-align: center;
      }

      .single-language {
        width: 50%;
        float: left;
        padding: 5px 5px 5px 14px;
        -webkit-transition: all .5s;
        transition: all .5s;

        &:hover {
          background-color: $light_grey_color;

          span {
            color: #fff;
          }
        }

        a {
          padding-right: 10px;

          span {
            margin-left: 5px;
            color: #333;
          }

          &:hover {
            background: 0 !important;
          }
        }
      }
    }
    &.open {
      .choose_language {
        background-color: #eee !important;
        box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.3);
      }
    }
  }

}

/* SELECT */
.content-wrapper {
  .select2-container {
    z-index: 101;
  }
}

.select2-container {
  z-index: 9999;
}

.select2-selection__rendered .select2-search.select2-search--inline {
  width: 80% !important;

  .select2-search__field {
    width: 100% !important;
  }
}

/*
PANELS
*/
.box {
  border-radius: 0;
}

/*
PANEL'S TRANSITION LEFT <-> RIGHT
*/
.animated-helper {
  width: calc(100% - 30px);
  position: absolute;
}

.animated-helper-form {
  width: calc(100% - 20px);
  position: absolute;
}

.content {
  position: relative;
}

/*
FORMS - PANEL
*/
form {

  label {
    line-height: 100%;
    margin: 0 0 6px 0;
  }

  input, select, textarea {

    border-radius: 0;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #ddd;
    border-color: #ddd;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &:focus {
      outline: 0;
      border: 0;
      border-bottom: 1px solid $light_orange_color;
    }

  }

  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  .form-control:focus {
    outline: 0;
    border: 0;
    border-bottom: 1px solid $light_orange_color;
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
  }

  .select2-container--default input:focus {
    border: none !important;
  }

  .select2-container--default .select2-selection--single,
  .select2-selection .select2-selection--single,
  .select2-container--default .select2-selection--multiple {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #ddd;

    &:focus {
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      border-bottom: 1px solid $light_grey_color;
    }

  }

}

/*
LIVE PREVIEW
*/
.list {

  .its_live {
    text-transform: uppercase;
    font-size: 15px;
  }

  .row {

    margin: 0 0 6px 0;

    .once:last-child {
      /* @include border(left, 1px, solid, black, .1);*/
      padding: 0 0 6px 12px;
    }

    @include border(bottom, 1px, solid, black, .1);

    &:last-child {
      border-bottom: none;
    }

  }

  .lp_header {

    font-size: 14px;
    font-weight: 800;
    margin: 0 0 3px 0;

    p {
      font-weight: 400;
      line-height: 120%;
      margin: 3px 0;
    }

    span {
      font-weight: 400;
    }
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    margin: 3px 0;
    padding: 0 0 6px 0;
  }

  .once {

    margin: 0;
    padding: 0 0 6px 0;

  }

}

/*
LIST
*/
.box-body.box-body-border-bottom {
  border-radius: 0;
  /*border-bottom: 1px solid red;*/
  box-shadow: 0 2px 2px -2px $grey_color;
  margin-bottom: 5px;
}

.widget {

  .col-xs-8 {
    padding-left: 0;
    padding-left: 0;
  }

  .widget_avatar {

    div {
      float: left;
    }

    h3, h4 {
      /*width: 100%;*/
    }

    .avatar {
      max-width: 60px;
      width: 100%;
      height: 60px;
      overflow: hidden;
      position: relative;
      float: left;
      margin: 15px 5px 0 0;

      background-color: #fff;
      @media (max-width: 600px) {
        float: none;
      }

      &.circle {
        border-radius: 50%;
      }

      .avatar_image {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      img {
        padding: 6px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      & + div {
        float: left;
        @media (max-width: 600px) {
          float: none;
        }
        h3, h4 {
/*          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;*/
        }
      }
    }

    .widget_type_information {
      width: 100%;
      font-size: 1em;
      float: left;
      padding: 0 0 0 1px;
      margin-top: 2px;
      margin-left: 20px;
      @media (max-width: 600px) {
        margin-left: 0;
      }
    }

  }
  /*end/widget_avatar*/
}

.widget_title {

  font-size: 1.5em;
  font-weight: 900;
  line-height: 100%;
  margin: 32px 0 3px 20px;
  /*float: left;*/
  @media (max-width: 600px) {
    margin-left: 0;
  }

}

.widget_list {

  .list_action {

    /*    position: absolute;
    right: 0;
    top: 0;*/
    /*display: table;*/
    width: 100%;
    margin-top: 20px;

  }
  .show-details-clear {
    clear: both;
  }

  button.show_details {
    display: block;
    width: 100%;
    margin: 40px 0 0 0;
  }

  .widget_addon {

    .addon_avatar {

      max-width: 70px;
      width: 100%;
      height: 70px;
      overflow: hidden;
      position: relative;
      float: left;
      margin: 0 6px 0 0;

      &.circle {
        border-radius: 50%;
      }

      .avatar_image {
        width: 100%;
        height: 100%;
        background-size: 100%;
        background-position: center center;
      }

      img {
        width: 100%;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

    }

  }

}

.list_action {

  display: block;
  min-height: 30px;

  i {

    font-size: 1.2em;
    margin: 0;
    padding: 0;
    -webkit-transition-duration: .4s;
    transition-duration: .4s;
    cursor: pointer;

    &:hover {

      color: $orange_color;
      -webkit-transition-duration: .4s;
      transition-duration: .4s;

    }

  }

}

.item-additional-info-container {
  padding: 10px 0;

  label {
    margin-right: 20px;

    i {
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }
  }
}

/* VALIDATION */
input[class*="parsley-error"] {
  border: 1px solid red !important;
}

input[class*="parsley-success"] {
  border: none;
  border-bottom: 1px solid #d2d6dc;
}

li[class*="parsley-"] {
  font-weight: 600;
  font-size: .94em;
  line-height: 100%;
  padding: 3px 0;
  color: red;
}

ul[id="parsley-id-multiple-transports[]"] {
  margin-top: -15px;
  margin-bottom: 15px;
}

/* GROUP */
.groups-box {

  .box-title {
    margin: 12px 0;
  }

  .group_action_title {
    font-weight: 600;
    text-transform: uppercase;
    padding: 5px 3px 5px 3px;
    /*border-bottom: 2px solid $grey_color;*/
    background-color: rgba($grey_color, 0.1);
    /*display: inline-block;*/
  }

}

.box-footer {
  border: none;
}

.new-group-box {

  margin: 0 0 12px 0;

  button {
    width: 100%;
  }

}

.once_group {

  border-left: 2px solid $orange_color;
  padding: 6px 0 6px 12px;
  margin: 12px 0;

  .list_action {

    padding: 0 0 6px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    margin: 0 0 6px 0;

  }

  .lp_header {

    margin: 0 0 6px 0;

  }

  .once {
    padding: 3px 0;
  }

}

/* EMPLOYEE*/
.widget-user-container {
  margin-bottom: 10px;

}

.widget-user-image {

  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  padding: 5px;
  text-align: center;
  /*margin: 0 auto;*/

  img {
    width: 100% !important;
    vertical-align: middle;
  }

  & + .lp_header {
    /*text-align: center;*/
    padding: 3px 5px;
  }

}

.local_booked_details {
  .booked_details_grey {
    margin-top: 10px;
  }
}

.booked_details_grey {
  background-color: rgba(0, 0, 0, 0.1);
}

/*
LIST ACTION
*/

.single-main-photo {
  height: 70px;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 70px;
  width: 100%;
  margin: 0 0 6px 0;
}

.photo {
  width: 25%;
  float: left;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 6px 6px 0 0;

  .single-photo {
    height: 100%;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    border: 2px solid transparent;
  }

  img {
    width: 100%;
  }

  .border {
    border: 2px solid transparent;
    height: 100%;
    width: 100%;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;

    i {
      display: none;
    }
  }

  .main {
    border: 2px dashed $light_orange_color;
    position: relative;
    padding: 1;

    i {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      font-size: 6em;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      @include color($orange_color, .5);
    }
  }

  .main-photo {
    width: 100%;
    display: block;
    position: absolute;
    height: 50%;
    cursor: pointer;
    z-index: 99;

    i {
      top: 50%;
      left: 50%;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 3.0em;
      color: $light_orange_color;
      display: none;
    }
  }

  .remove-photo {
    width: 100%;
    display: block;
    position: absolute;
    height: 50%;
    cursor: pointer;
    bottom: 0;
    z-index: 99;

    i {
      top: 50%;
      left: 50%;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 3.0em;
      color: $light_orange_color;
      display: none;
    }
  }
}

.photo:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.photo > .photocontent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.photos-preview {

  .photo {
    width: 25%;

    .single-photo {
      width: 25%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .photos-preview {

    .photo {
      width: 33.3%;

      .single-photo {
        width: 33.3%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .photo {
    width: 33.3%;
  }
}

.photo:hover {

  .main {

    i {
      display: none;
    }
  }

  .main-photo {
    @include background($orange_color, .3);

    i {
      display: block;
    }
  }

  .remove-photo {
    @include background($orange_color, .3);

    i {
      display: block;
    }
  }

  .main-photo:hover, .remove-photo:hover {
    background-color: rgba(224, 142, 11, 0.4);
  }
}

/*modal styles*/

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: block;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.modal-wrapper-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  margin-bottom: 100px;
  width: 60%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .select2-container {
    z-index: 100 !important;
  }
  .fa.fa-times {
    cursor: pointer;
  }
}

.modal-wrapper-small {
  width: 30% !important;
}

.modal-container {
  margin: 0px auto;
  padding: 15px 15px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  width: 100%;
  max-height: 600px;
  min-height: 400px;

  .message {

    margin: 12px 0;

  }

}

.big-modal-container {
  /* max-width: 640px;*/
  /* width: 100%;*/
}

.small-modal-container {
  /* max-width: 320px;*/
  /* width: 100%;*/
}

.transport-activity .fc-toolbar button.fc-prev-button, .transport-activity .fc-toolbar button.fc-next-button {
  font-size: 14px;
}

.calendar-modal .fc-highlight {
  background-color: rgba($orange_color, 0.7);
}

.calendar-modal .fc-today {
  background-color: rgba($orange_color, 0.5);
}

.calendar-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  -webkit-transform: none;
  transform: none;
  top: 0;
  left: 0;
  margin-left: 0 !important;
  margin-top: 0 !important;
  display: block;

  .modal-container {
    height: 100%;
    max-height: inherit;
    /*overflow: hidden;*/

    .box-body {
      height: 100%;

      .event-form {
        position: fixed;
        /*height: 100%;*/

        .row {

          .once {
            padding: 0 0 6px 0;
          }

          .once:last-child {
            /* @include border(left, 1px, solid, black, .1);*/
            padding: 0 0 6px 12px;
          }
        }

      }
    }
  }
}

.calendar-modal .modal-container.transport_activity_modal .box-body .event-form .row .once {
  padding: 0 0 6px 0;
}

.calendar-modal .modal-container .box-body .event-form .row .once:last-child {
  padding: 0 0 6px 0;
}

.calendar-modal .event-form {
  display: inline-block;
}

.fc-scroller.fc-day-grid-container {
  /*overflow-x: hidden;*/
  /*overflow-y: hidden;*/
  height: inherit !important;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter, .modal-leave {
  opacity: 0;
}

.modal-leave {
  z-index: -1;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* file input styles*/
.mk_upload_image_inf {
  width: 100%;
  min-height: 50px;
  margin-bottom: 5px;

  i.fa.fa-info-circle {
    font-size: 2.5em;
    color: #fff;
  }

  p {
    font-size: 1.1em;
  }

  div {
    float: left;
    display: table-cell;
    min-height: 50px;

    &:first-of-type {
      /*max-width: 100px;*/
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background-color: $light_orange_color;
      padding: 5px;
    }
    &:last-of-type {
      font-size: 0.8em;
      width: calc(100% - 52px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding-left: 10px;
      /*text-align: justify;*/
    }
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile-label {
  width: 100%;
  margin: 0.5% 0;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
  height: 125px;
}

.no-js .inputfile-label {
  display: none;
}

.inputfile-label:focus,
.inputfile-label.has-focus {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile-label {
  color: #f39c12;
  border: 2px dashed #f39c12;

  span {
    line-height: 125px;
    display: block;
    text-align: center;
    position: relative;
    height: 100%;

    i {
      display: block;
      position: absolute;
      top: 30%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}

.inputfile-label:focus,
.inputfile-label.has-focus,
.inputfile-label:hover {
  color: #e08e0b;
  @include background(#f46026, .3);
}

/* loader styles*/

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.98);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999999999;

  img {
    width: 50px;
    height: 50px;
    animation: cssload-rotate 2000ms linear infinite;
    -o-animation: cssload-rotate 2000ms linear infinite;
    -ms-animation: cssload-rotate 2000ms linear infinite;
    -webkit-animation: cssload-rotate 2000ms linear infinite;
    -moz-animation: cssload-rotate 2000ms linear infinite;
  }

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;

    .loader-additional-info {
      margin-top: 18px;
    }
  }
}

    
/* insurance traveler modal styles*/

.insurance-traveler-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.98);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999999999;

  .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    p {
      font-size: small;
    }

    .loader-additional-info {
      margin-top: 18px;
    }
  }
}


/* TRANSPORT ACTIVITY */
.transport-activity {

  form .row {
    border-bottom: none !important;
  }

  .fc-toolbar {

    .fc-center {

      font-weight: 600;
      height: 44px;
      line-height: 44px;
      text-transform: uppercase;

    }

    button {

      box-shadow: none;
      text-shadow: none;
      border-radius: 0;
      height: auto;
      margin: 0;
      border-bottom: 2px solid rgba($light_grey_color, .3);

      @extend .default_grey_button;

      &:focus {
        outline: 0;
      }
      &:hover {

        background: lighten($light_grey_color, 30);
        -webkit-transition: background .5s ease-out;
        transition: background .5s ease-out;

      }

      &.fc-prev-button,
      &.fc-next-button {
        padding: 14px 15px;
      }

      &.fc-today-button {
        margin: 0 0 0 12px;
        @extend .default_orange_button;
        border-bottom: 2px solid rgba($light_orange_color, .3);
      }

    }

  }

}

.fc-agendaWeek-view,
.fc-agendaDay-view {

  .fc-content {

    span:first-of-type {
      float: left;
      margin: 0 3px 0 0;

      i {
        font-size: .85em;
      }

    }

    .fc-time {
      margin: 3px 0 0 0;
    }

    .fc-title {
      clear: both;
    }

  }

}

.fc th {

  font-size: .84em;
  padding: 6px 0;

}

.fc-event {
  padding: 2px 10px;
  border-radius: 0;
  cursor: pointer;
}

.fc-time {
  margin-left: 6px;
}

/*.fc-bg { opacity: 0 !important; }*/

/* DATEPICKER */
.datepicker {
  display: block !important;
}

.bootstrap-datetimepicker-widget * {
  border-radius: 0 !important;
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: $orange_color !important;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td span.active {
  background: $orange_color;
  -webkit-transition: background .5s ease-out;
  transition: background .5s ease-out;

  &:hover {
    background: $light_orange_color;
    -webkit-transition: background .5s ease-out;
    transition: background .5s ease-out;
  }

}

.bootstrap-datetimepicker-widget {
  border: 1px solid rgba($light_grey_color, 0.5);
}

.bootstrap-datetimepicker-widget .timepicker {

  .table-condensed {

    tr, td {
      border: 0 !important;
    }

    span.fa {
      color: $orange_color;
      font-size: 1.5em;
    }
  }
}

/* BUTTONS */
.button {
  float: left;
  display: block;
  margin: 12px 0;
  padding: 1em 2em;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;

  &.full_width {
    max-width: 100%;
    width: 100%;
  }

  &.small {

    span {
      padding: 9px !important;
    }
    &:before {
      padding: 9px !important;
    }

  }

}

.button:focus {
  outline: none;
}

.button > span {
  vertical-align: middle;
}

/* Sizes */
.button--size-s {
  font-size: 14px;
}

.button--size-m {
  font-size: 16px;
}

.button--size-l {
  font-size: 18px;
}

/* Typography and Roundedness */
.button--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button--text-thin {
  font-weight: 300;
}

.button--text-medium {
  font-weight: 500;
}

.button--text-thick {
  font-weight: 600;
}

.button--round-s {
  border-radius: 5px;
}

.button--round-m {
  border-radius: 15px;
}

.button--round-l {
  border-radius: 40px;
}

/* Borders */
.button--border-thin {
  border-bottom: 1px solid;
}

.button--border-medium {
  border-bottom: 2px solid;
}

.button--border-thick {
  border-bottom: 3px solid;
}

/* Rayen */
.button--rayen {
  overflow: hidden;
  padding: 0;
  width: 230px;

  &.orange_color {
    border-color: $orange_color;

    &:before {
      background: $orange_color;
    }

  }

  &.grey_color {
    border-color: $grey_color;

    &:before {
      background: $grey_color;
    }

  }

}

.button--rayen.button--inverted {
  color: #fff;
}

.button--rayen::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(-101%, 0, 0);
  transform: translate3d(-101%, 0, 0);
  color: #fff;
}

.button--rayen.button--inverted::before {
  background: #fff;
  color: #fff;
}

.button--rayen > span {
  display: block;
}

.button--rayen::before,
.button--rayen > span {
  padding: 1em 2em;
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.button--rayen:hover::before {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.button--rayen:hover > span {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

/* Sacnite */
.button.button--sacnite {
  width: 28px;
  height: 28px;
  min-width: 0;
  padding: 0;
  /*color: #fff;*/
  color: $grey_color;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.button--scanite.button--round-l {
  border-radius: 50%;
}

.button--sacnite.button--inverted {
  color: $grey_color;
}

.button--sacnite::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: -1;
  /*box-shadow: inset 0 0 0 35px $grey_color;*/
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3;
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.button--sacnite.button--inverted::before {
  box-shadow: inset 0 0 0 35px #fff;
}

.button--sacnite .button__icon {
  font-size: 16px;
  width: 22px;
}

.button--sacnite > span {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.button--sacnite:hover {
  color: $grey_color;
  .icon {
    color: $grey_color;
  }
}

.button--sacnite.button--inverted:hover {
  color: #fff;
}

.button--sacnite:hover::before {
  box-shadow: inset 0 0 0 2px $grey_color;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.button--sacnite.button--inverted:hover::before {
  box-shadow: inset 0 0 0 2px #fff;
}

/*
FILTERS BOX
*/

@keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.animated-helper {
  width: calc(100% - 30px);
  position: absolute;
}

.page_overlay {
  position: fixed;
}

.content {
  position: relative;
}

.slogan {
  height: 50px;
}

.logo-lg {
  img {
    width: 100%;
  }
}

/* REVIEWS */
.user_reviews {

  border-top: 2px solid $light_orange_color;
  /* border-bottom: 1px solid rgba($light_grey_color, .2);*/
  margin: 0 0 24px 0;

  .reviews {
    /*min-height: 70px;*/
    /*line-height: 70px;*/
    padding: 20px 0;

    .col-xs-3 {
      &:last-child {
        .single_review {
          border-right: 0;
        }
      }
    }

    .single_review {

      border-right: 1px solid $light_orange_color;
      text-align: center;
      margin-top: 20px;

      .review_count {
        display: inline-block;
        font-size: 2.6em;
        font-weight: 700;
        text-align: center;
      }

      span {
        display: inline-block;
        max-width: 90px;
        /*width: 100%;*/
        line-height: 120%;
        text-align: center;
        margin: 0 0 0 0;
      }

    }
  }

  .widget-user {
    border-top: none;
    /*text-align: center;*/
  }

}

.client_review {

  div.tooltip-inner {
    width: 150px;
  }

  margin-bottom: 24px;
  /*border-top: 3px solid $light_orange_color;*/
  padding-top: 15px;

  .client_name {
    display: inline-block;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 100%;
  }
  .review_added_time {
    font-weight: 400;
    font-size: 0.9em;
  }

  .recommended {
    font-size: 1em;
    position: absolute;
    right: 15px;
    top: 15px;

    i {
      font-size: 1.3em;
      color: $orange_color;
    }
  }

  blockquote {
    font-style: italic;
    color: $grey_color;
    line-height: 130%;
    padding: 0;
    margin: 3px 0;
    max-width: 20%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-transition: all .5s;
    transition: all .5s;
  }

  .show_review_types {
    position: relative;
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba($light_grey_color, 0.6);
    text-align: center;
    padding: 10px;
    padding-bottom: 15px;

    color: $orange_color;
    clear: both;
    /*padding: 10px;*/
    text-transform: uppercase;
    font-size: .84em;
    font-weight: 600;
    cursor: pointer;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    i {
      color: $light_orange_color;
      font-size: 1.2em;
    }

    &:hover {

      color: $light_orange_color;
    }

    .triangle-bottom {

      top: 38px;
      left: 50%;

    }
    .triangle-bottom-2 {

      top: 37px;
      left: 50%;

    }
    .triangle-top {

      top: 29px;
      left: 50%;

    }
    .triangle-top-2 {

      top: 30px;
      left: 50%;

    }

  }

  &.showed {
    blockquote {
      max-width: 50%;
      text-overflow: none;
      white-space: normal;
    }
    .reviews_types {
      max-height: 500px;
    }
  }

  .reviews_types {
    margin: 6px 0 0 0;
    /*border-top: 1px solid rgba($light_grey_color, .3);*/
    padding: 12px 0 0 0;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .5s;
    transition: max-height .5s;

    .type {
      margin: 1px 0 6px 0;
      padding: 0 0 6px 0;
      border-bottom: 1px solid rgba($light_grey_color, .1);
      &:last-child {
        border-bottom: none;
      }
    }

  }

  .type_name {
    text-transform: uppercase;
    font-weight: 500;
    margin: 0 0 6px 0;
    display: inline-block;
    line-height: 20px;
  }

}

.star_rating {

  position: relative;
  display: inline-block;
  letter-spacing: 5px;
  margin-bottom: 5px;

  &:before {
    display: block;
    font-size: 130%;
    top: 0;
    left: 0;
    font-family: FontAwesome;
    content: "\f005\f005\f005\f005\f005";
    color: #818181;
    /*text-shadow: 0 2px darken(#818181,20%);*/
    padding-left: 5px;
  }

  span {

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    overflow: hidden;

    &:before {
      font-size: 130%;
      font-family: FontAwesome;
      color: #ffbb00;
      position: absolute;
      left: 0;
      top: 0;
      content: "\f005\f005\f005\f005\f005";
      text-shadow: 0 1px darken(#ffbb00, 20%);
      display: block;
      padding-left: 5px;
    }

  }

}

.widget-user {
  min-height: 100px;
  margin: 0 auto;
  display: block;
  border-top: 2px solid $light_orange_color;
  box-shadow: none;
  margin-bottom: 0;
  padding: 10px 15px;

  .user_avatar {
    width: 60px;
    float: left;
    margin: 10px 0;

    img {
      width: 100%;
      height: auto;
    }

    .avatar {
      max-width: 60px;
      width: 100%;
      height: 60px;
      overflow: hidden;
      position: relative;
      float: left;
      margin-right: 5px;

      background-color: rgba($grey_color, 0.2);

      &.circle {
        border-radius: 50%;
      }

      .avatar_image {
        background-size: 100%;
        background-repeat: no-repeat;
        height: 100%;
        background-position: 50%;
      }
    }

  }

  .widget-user-header {

    float: left;
    height: auto;
    padding: 5px 15px;

    .widget-user-desc {
      text-transform: capitalize;
    }

  }

}

/* ACCOUNT */
.account {

  .box-body {
    padding: 0 15px;
    margin: 0 0 36px 0;
  }

  ul {

    li {
      padding: 6px 0;
      margin: 3px 0;
      border-bottom: 1px solid rgba(0, 0, 0, .1);

      &:last-child {
        border-bottom: 0;
      }

      h4 {
        font-size: 1em;
        line-height: 100%;
        margin: 0 0 3px 0;
      }

      p {

        font-size: .94em;

        span {
          margin: 3px;
          display: inline-block;
        }

      }

    }

  }

  .border-right {
    border-right: 1px solid $light_grey_color;
  }

  .account_divider {
    width: 100%;
    background-color: rgba($light_grey_color, 0.3);
    height: 1px;
    margin: 0 0 10px 0;
  }
}

@media screen and (max-width: 550px) {

  .user_reviews {

    .reviews {

      .col-xs-3 {
        width: 50%;
      }

      .single_review {
        border: 0;
        text-align: left;

        span {
          text-align: left;
        }

        .review_count {

          text-align: center;
        }
      }
    }

  }

  .show_review_types {

    button {
      width: 100%;
    }
  }

  .client_review {

    .star_rating {
      width: 100%;
    }
  }

}

@media screen and (max-width: 991px) {

  .widget-user {

    text-align: center;

    .user_avatar {
      float: initial;
      margin: 0 auto;
    }

    .widget-user-header {
      float: initial;

    }
  }

  .client_review {

    &.showed {
      blockquote {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  
 .modal-wrapper-small {
  width: 95% !important;
}

}

@media screen and (min-width: 1325px) {

  .widget-user {

    .widget-user-header {
      margin-top: 15px;

    }
  }

}

@media screen and (max-width: 550px) {

  .review-break-line {
    display: none;
  }

}

@media screen and (min-width: 1450px) {

  .review-break-line {
    display: none;
  }

}

@media only screen and (max-width: 991px) {

  .live_preview {

    .row {

      .once:last-child {
        padding: 0 0 3px 0;
      }

    }

  }
}

@media only screen and (max-width: 768px) {

  .modal-wrapper {
    width: 85%;
  }

  .modal-wrapper.calendar-modal {
    width: 100%;
  }

  .photo {
    /*width: 50%;*/
  }

  .widget-user-image {

    margin: 0 auto;

    & + .lp_header {
      text-align: center;
      padding: 3px 5px;
    }

  }
  .show-details-clear {
    button {
      width: 100%
    }
  }

}

@media only screen and (max-width: 560px) {

  .modal-wrapper {
    width: 96%;
  }

}

/*preview image*/
.lp_header_images {
  img {
    height: 70px;
    max-width: 70px;
    width: 100%;
  }
}

.group_data_button {
  background-color: $orange_color;
  padding: 5px 10px;

  span {
    color: #fff;
    font-weight: 600;
  }
}

.group-box button {
  border-bottom: 2px solid $grey_color;
}

.fc-create-group-button {
  color: $grey_color;
  border-bottom: 2px solid $light_orange_color;
}

.group_data {
  /*padding-bottom: 7px;*/
  /*min-width: 120px;*/
  /*border-bottom: 1px solid rgba($grey_color, 0.3);*/
  margin: 0 5px;

  span {
    background-color: $orange_color;
    color: #fff;
    padding: 5px 30px;
    font-size: .8em;
    float: right;
  }

  &.payment_type_label {

    span {
      padding: 5px 15px;
      background-color: rgb(199, 55, 37);
      font-size: .9em;

      i {
        font-weight: 600;
      }
    }
  }
}

/*transport*/
.box.list-item-box {
  border-top: 0;
  box-shadow: none;

  .box-header.with-border {
    border: 0;
    .box-title {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba($grey_color, 0.3);
    }
  }
}

.show_hide_details_button {
  color: $orange_color;
  cursor: pointer;
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba($light_grey_color, 0.6);
  text-align: center;
  padding: 1px;
  padding-bottom: 5px;
  text-transform: uppercase;
  font-size: .84em;
  font-weight: 600;

  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;

  &:hover {
    color: $light_orange_color;
  }

  i {
    color: $light_orange_color;
    font-size: 1.2em;
  }

}

.triangle-bottom {
  position: absolute;
  top: 24px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: rgba($grey_color, 0.6) transparent transparent transparent;

}

.triangle-bottom-2 {
  position: absolute;
  top: 23px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #fff transparent transparent transparent;

}

.triangle-top {
  position: absolute;
  top: 14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent rgba($grey_color, 0.6) transparent;

}

.triangle-top-2 {
  position: absolute;
  top: 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff transparent;

}

.result_list {
  .triangle-bottom {
    top: 43px;
  }
  .triangle-bottom-2 {
    top: 42px;
  }
  .triangle-top {
    top: 32px;
  }
  .triangle-top-2 {
    top: 33px;
  }
}

.details .col-sm-12 {
  padding-left: 0;
}

.details {
  .col-xs-12 {
    padding-top: 5px
  }

  .row {
    border-bottom: 1px solid #d2d6de;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .lp_header {
    // margin-top: 5px;
  }
  span.lp_header + span {
    font-size: 14px;
  }

}

.details {

  max-height: 0;
  overflow-y: scroll;
  -webkit-transition: max-height .5s;
  transition: max-height .5s;

  &.showed {
    max-height: 1000px;
  }

  h4 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }

  .additional_information, .details_images, .additional-info {
    margin-top: 10px;
  }

  .item-description-content, .item-pined-offert-container {
    margin: 10px 0;
  }

  .item-description-content {

    .offer_localization {
      margin-top: 10px;

      span {
        font-weight: 600;
      }
    }
  }

  .item-offer-pinned .row .row {
    border: 0;
  }

  .details_images {

    h4 {
      margin-bottom: 5px;
    }
    .details_img {
      float: left;
      height: 150px;
      max-width: 100%;
      padding: 4px 4px 0 0;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      /*            margin-right: 5px;
      margin-top: 5px;*/
    }
    .photo-preview {
      padding: 10px 0;
    }

    .list-item-photo {
      float: left;
      height: 250px;
      width: 30%;
      margin-right: 20px;
      margin-bottom: 20px;
      background-repeat: no-repeat;
      /*background-size: 100%;*/
      background-size: contain;
      background-position: center;

    }
  }

  .lp_header_payment {
    font-size: 1.2em;
    margin-bottom: 10px
  }

}

/*Offers*/
.add_offer_active {
  background-color: $orange_color;
  color: #fff;

  &:active, &:focus {
    color: #fff;
  }
}

.group_data.item-id-label span {
  background: none;
  color: #333;
  font-weight: 600;
}

.widget.widget_list.offer-item-container {
  /*padding-bottom: 35px;*/
}

.offer-item-container {

  .offer-item-more-button {
    cursor: pointer;
    margin-top: 5px;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    font-size: 1em;
    color: #fff;
    width: 100%;
    /*max-width: 200px;*/
    background-color: $orange_color;
    border: 0;

    -webkit-transition: all 0.5s linear;
    -webkit-transition: all 2s linear;
    transition: all 2s linear;

    &:hover {
      background-color: $light_orange_color;
    }
  }
}

.offer-item-padding-top {
  padding-top: 20px;
}

.offer-item-head {
  /*padding-bottom: 50px;*/

  .details {

    .row {
      &:last-of-type {
        border: 0;
      }
    }
  }

  .offer-item-activity {
    /*position: absolute;*/
    bottom: 0;
    /*right: 4px;*/
  }

  .list_action {
    padding-top: 10px;
    margin-top: 0;
  }

}

.offer-item-activity {
  @media (min-width: 992px) {
    border-left: 1px solid rgba($light_grey_color, 0.3);
  }
}

.lp_header {

  font-size: 14px;
  font-weight: 800;
  margin: 0 0 6px 0;
  display: block;

  p {
    font-weight: 400;
    line-height: 120%;
    margin: 3px 0;
  }

  span {
    font-weight: 400;
  }

}

.offer-item-heading {
  padding-top: 30px;
  padding-bottom: 16px;
  font-size: 1.2em;
  font-weight: 600;

  i {
    width: 100%;
    text-align: center;
  }

  div:first-of-type {

    h4 {
      text-align: left;
    }
  }

  div:last-of-type {

    h4 {
      text-align: right;
    }
  }
}

.offer-points-container {

}

.offer-middle-point {

  .offer-item-point-tooltip {
    min-width: 300px;
  }
}

.offer-item-points {

  span {

    &.offer-item-hidden {
      opacity: 0;
    }

  }
  .offer-points-line {
    border-top: 2px solid $orange_color;
    margin-top: -10px;
    text-align: left;
  }

  i {
    color: $orange_color;
    width: 100%;
    text-align: center;

    .offer-item-point-tooltip {
      font-family: $standard_font_family;
      z-index: 9998;
      display: none;
      padding: 10px;
      background-color: #fff;
      border: 1px solid rgba($light_grey_color, 0.3);

      color: initial;

      -webkit-transition: all 0.5s linear;
      -webkit-transition: all 2s linear;
      transition: all 2s linear;
    }

    &:hover {

      .offer-item-point-tooltip {
        z-index: 9998;
        display: block;
        position: absolute;
        left: 50%;
        bottom: 60px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);

      }

      .offer-tooltip-triangle {
        z-index: 9998;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 15px 0 15px;
        border-color: rgba($light_grey_color, 0.3) transparent transparent transparent;
        position: absolute;
        bottom: 45px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
      .offer-tooltip-triangle-2 {
        z-index: 9999;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 15px 0 15px;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        bottom: 47px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
  .offer-middle-point {

    .offer-item-point-tooltip {

      ul {
        li {
          line-height: 1.2em;
        }
      }
    }
  }
  h4 {
    text-align: center;
  }

  div:first-of-type {
    h4 {
      text-align: left;
    }
    i {
      text-align: left;

      &:hover {

        .offer-item-point-tooltip {
          z-index: 9998;
          left: 0;
          display: block;
          position: absolute;
          -webkit-transform: none;
          transform: none;
        }

        .offer-tooltip-triangle {
          z-index: 9998;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 15px 0 0;
          border-color: rgba($light_grey_color, 0.3) transparent transparent transparent;
          position: absolute;
          left: 10px;
          bottom: 45px;
          -webkit-transform: none;
          transform: none;
        }
        .offer-tooltip-triangle-2 {
          z-index: 9999;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 15px 0 0;
          border-color: #fff transparent transparent transparent;
          position: absolute;
          left: 11px;
          bottom: 47px;
          -webkit-transform: none;
          transform: none;
        }
      }
    }
    .offer-points-line {
      margin-left: 12px;
    }
  }

  div:last-of-type {
    h4 {
      text-align: right;
    }
    i {
      text-align: right;

      &:hover {

        .offer-item-point-tooltip {
          min-width: 100px;
          display: block;
          position: absolute;
          right: 0;
          -webkit-transform: none;
          transform: none;
          text-align: center;
        }

        .offer-tooltip-triangle {
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 15px 15px 0;
          border-color: transparent rgba($light_grey_color, 0.3) transparent transparent;
          position: absolute;
          left: calc(100% - 30px);
          bottom: 45px;
          -webkit-transform: none;
          transform: none;
        }
        .offer-tooltip-triangle-2 {
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 15px 15px 0;
          border-color: transparent #fff transparent transparent;
          position: absolute;
          left: calc(100% - 31px);
          bottom: 47px;
          -webkit-transform: none;
          transform: none;
        }
      }
    }
    .offer-points-line {
      margin-right: 12px;
    }
  }
}

.offer-item-total {
  /*margin-top: 100%;*/
  text-align: left;
  /*margin-top: 100%;*/

  h4 {
    padding: 5px 0;
    span {
      font-weight: 600;
      font-size: 1.2em;
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: 1px solid rgba($light_grey_color, 0.3);
    }
  }
  h5 {
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 5px;

    span {

      font-weight: 600;

    }
    .total_price {
      font-size: 1.4em;
    }
  }
}

.local_payment_info {
  margin-top: 10px;

  h5 {
    padding: 0;
    line-height: 1.5em;
    font-size: .8em;
  }
  h5:first-of-type,
    /*h5:nth-of-type(2)*/
  {
    font-size: .9em;
    font-weight: 600;
  }

  &.local_payment_info_booked {
    margin-bottom: 10px;
  }

  .payments_details {
    margin-bottom: 10px;
    h5 {
      font-size: .9em;
      font-weight: 600;
      /*line-height: 120%;*/

      span {
        font-weight: 400;
      }
    }
  }
}

.advance_required_info {
  background-color: #f7cfbe;
  text-align: center;
  padding: 5px !important;
  margin-bottom: 5px;
}

.lp_header_payment {

  .lp_header_rent_time {
    font-weight: 400;
  }
}

.offer-item-left-container.booked_details_grey {
  width: 100%;
}

.paid_status {
  font-weight: 400;
}

.price_currency {
  text-transform: uppercase;
}

.offer-item-map {
  width: 100%;
  margin: 20px 0;
}

.list-item-box {
  padding-top: 15px;
}

.list-search:hover {
  background: #eee;

  .triangle-bottom-2 {
    border-color: #eee transparent transparent transparent;
  }

  .triangle-top-2 {
    border-color: transparent transparent #eee transparent;
  }
}

.bg:nth-child(odd) > div > .box {
  background-color: #eee;

  .triangle-bottom-2 {
    border-color: #eee transparent transparent transparent;
  }

  .triangle-top-2 {
    border-color: transparent transparent #eee transparent;
  }
}

.offer-item-employees-transport-container {

  padding: 5px 0;
  margin-bottom: 16px;

  .offer-item-employees {
    margin-top: 15px;
    /*cursor: pointer;*/

  }
  .offer-item-transport {
    margin-top: 15px;
    /*cursor: pointer;*/
    font-size: .9em;
  }

  .lp_header {
  }

  .avatar {
    max-width: 60px;
    width: 100%;
    height: 60px;
    overflow: hidden;
    position: relative;
    float: left;
    margin-right: 5px;
    z-index: 10;

    background-color: #fff;

    &.circle {
      border-radius: 50%;
    }

    .avatar_image {
      width: 100%;
      height: 100%;
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    img {
      padding: 6px;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .avatar-80 {
    max-width: 80px;
    width: 100%;
    height: 80px;
    overflow: hidden;
    position: relative;
    float: left;
    margin-right: 5px;
    z-index: 10;

    background-color: #fff;

    &.circle {
      border-radius: 50%;
    }

    .avatar_image {
      width: 100%;
      height: 100%;
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    img {
      padding: 6px;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}

.offer-item-employee-detail-80 {

  float: left;
  width: calc(100% - 90px);

  h4 {
    margin-bottom: 8px;

    span {
      padding-top: 10px;
      font-weight: 600;
      display: inline-block;
      border: none;
    }

    /* &:first-of-type{*/
    /*     margin-bottom: 2px;*/
    /*     margin-right: 16px;*/
    /*     span{*/
    /*         border-bottom: 1px solid rgba($light_grey_color, 0.3);*/
    /*         padding-bottom: 4px;*/
    /*         font-weight: 400;*/
    /*         display: block;*/
    /*     }*/
    /* }*/
  }
  p {
    font-size: 13px;
    display: inline-block;
    margin-right: 10px;
    span {
      font-weight: bold;
    }
  }
}

.offer-item-employee-detail {

  float: left;
  width: calc(100% - 70px);

  h4 {
    margin-bottom: 8px;

    span {
      padding-top: 10px;
      font-weight: 600;
      display: inline-block;
      border: none;
    }

    /* &:first-of-type{*/
    /*     margin-bottom: 2px;*/
    /*     margin-right: 16px;*/
    /*     span{*/
    /*         border-bottom: 1px solid rgba($light_grey_color, 0.3);*/
    /*         padding-bottom: 4px;*/
    /*         font-weight: 400;*/
    /*         display: block;*/
    /*     }*/
    /* }*/
  }
  p {
    font-size: 13px;
    display: inline-block;
    margin-right: 10px;
    span {
      font-weight: bold;
    }
  }
}

.lang-display {
  background-color: #424C50;
  border: 1px solid #ddd;
  border-radius: 0;
  cursor: default;
  margin-right: 5px;
  margin-top: 5px;
  padding: 2px 10px;
  color: #fff;
  font-size: 14px;
}

/* OFFER ITEM LOCAL RENT*/
.local_rent_point {

  span {
    font-size: 1.1em;
    line-height: 120%;
  }

  span:nth-of-type(2) {
    font-weight: 600;
    display: inline-block;
    width: 100%;
  }
  span:nth-of-type(1) {
    font-size: 1.2em;
  }
}

.offer-type-label {
  padding-bottom: 7px;

  span {
    background-color: $grey_color;
    color: #fff;
    padding: 5px 20px;
    font-size: .8em;
    float: right;

    &.label_own_booking_system {
      background-color: #197194;
    }
  }
}

.offer-price-type {
  .offer-price-type-label {
    width: 100%;
  }
}

/* clockpicker*/
.clockpicker-popover {
  z-index: 9999;

  .clockpicker-canvas {

    svg {

      line {
        stroke: rgba($orange_color, 0.5);
      }

      .clockpicker-canvas-fg {
        fill: $orange_color;
      }
      .clockpicker-canvas-bg {
        fill: $light_orange_color;
      }

      .clockpicker-canvas-bearing {
        fill: $light_orange_color;
      }

    }
  }
  .popover-title {

    .text-primary {
      color: $orange_color;
    }
  }

  .popover-content {
    background-color: #fff !important;
  }

  .clockpicker-tick {
    /*background-color: rgba($orange_color, 0.5);*/
    /*font-weight: 600;*/
  }

  .clockpicker-tick.active, .clockpicker-tick:hover {
    background-color: $orange_color;
  }
}

/* Account, fix problem with hidden border:*/

input[class*="parsley-success"] {
  border-bottom: 1px solid #ddd !important;
  border-color: #d2d6de !important;
}

input[class*="parsley-success"]:focus {
  border-bottom: 1px solid #ddd !important;
  border-color: $light_orange_color !important;
}

.parsley-required {
  /*border-bottom: 1px solid rgba(0,0,0,0.1) !important;*/
}

/* Profile fulfillment bar and comunicates*/
.box.box-default.profile_fulfillment {
  border: 0;
  box-shadow: none;
}

.profile_fulfillment {

  .profile_fulfillment_container {
    margin: 15px;
    border: 2px solid $light_orange_color;
  }

  h2 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 10px;

    &.progress_details {
      cursor: pointer;

      &:hover {

      }
    }
  }

  /*border: 2px solid $orange_color !important;*/

  .progress {
    height: 30px;

    .progress-bar {
      background-color: $orange_color;
      line-height: 30px;
      font-size: 1.2em;
    }
  }

  .profile_uncomplete_elements {

    /*display: none;*/

    h3 {
      font-size: 1em;
      font-weight: 600;
    }
    ul {
      font-size: .8em;

      li {
        border: 0;
      }
    }

    span {
      width: 100%;
      display: inline-block;
      text-align: left;
      padding-top: 2px;

      &.profile_data_percent {
        display: block;
      }
    }
  }

  .profile_data {
    border-right: 1px solid $grey_color;
    text-align: center;
    font-size: 0.9em;
    /*width: calc(25% - 2px);*/
    /*max-width: 200px;*/
    /*float: left;*/
    /*min-height: 60px;*/
    /*background-color: rgba($orange_color, 0.8);*/
    /*margin-right: 2px;*/
    /*padding: 10px 5px;*/
    -webkit-transition: background-color .5s;
    transition: background-color .5s;

    .fa.fa-check-circle-o {
      display: none;
    }

    &:hover {
      /*background-color: rgba($orange_color, 1);*/
    }
    &:last-of-type {
      border-right: 0;
    }
    &.profile_item_added {
      color: rgba($light_grey_color, .6);

      .fa.fa-check-circle-o {
        display: inline-block;
      }
      .fa.fa-question-circle {
        display: none;
      }
    }

    span {
      font-size: 1em;
    }

    h3 {
      text-align: center;
      font-size: 1.2em;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 1em;
      font-weight: 600;
      margin-bottom: 5px 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

  }
}

.reviews_container {

  .star_reviews {
    position: relative;
    display: inline-block;
    float: left;

    &:before {
      font-size: 1.5em;
      line-height: 1.5em;
      display: block;
      top: 0;
      left: 0;
      font-family: FontAwesome;
      content: "\f005\f005\f005\f005\f005";
      color: #818181;
      /*text-shadow: 0 2px darken(#818181,20%);*/
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: block;
      overflow: hidden;

      &:before {
        font-size: 1.5em;
        line-height: 1.5em;
        font-family: FontAwesome;
        color: #ffbb00;
        position: absolute;
        left: 0;
        top: 0;
        content: "\f005\f005\f005\f005\f005";
        text-shadow: 0 1px darken(#ffbb00, 20%);
        display: block;
      }
    }
  }
  .text {
    display: inline-block;
    line-height: 36px;
    padding: 0 10px;
    font-weight: 900;
    min-width: 80px;
    text-align: right;
  }

}

.booking-transport-checkbox {
  position: absolute;
  right: 10;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  line-height: 1.5em;

  label {
    font-weight: 900;
    font-size: larger;

    .icheckbox_square-orange {
      margin-right: 5px;
    }
  }
}

.image_upload_validation {
  display: none;
  color: red;
  font-weight: 600;
  font-size: .94em;
  padding: 0 5px;
}

.booking_history_filter {
  padding: 10px;
  /*margin-bottom: 20px;*/

  .booking_history_filter_div {
    float: left;
  }

  input {
    &.datepicker {
      display: inline-block !important;
      max-width: 120px;
      margin-right: 10px;
      text-align: center;
    }
    &:first-of-type {
      /*margin-left: 10px;*/
    }
  }
}

.dashboard_active_offers {
  a {
    color: $grey_color;
  }
}

.report_problem_button {
  cursor: pointer;
}

.report_problem_container {

  .report_problem_form_group {
    margin-top: 15px;
  }
}

.group_checkbox_valid_container{
    margin-bottom: 5px;
}

.group_checkbox_valid{
    font-size: .94em;
    font-weight: 600;
    color: red;
    display: none;
}



.itemDetailsInformation{
    background: none;
    padding: 0;
    
    .details{
        overflow: hidden;
    }
    
    h3.widget_title{
        margin-top: 20px;
    }
}

.hideOverflow{
    overflow-x: hidden;
}

.showItemDetails{
    cursor: pointer;
}

.item-details-modal-container{
    .details{
        max-height: initial;
    }
    
    .details .details_images .list-item-photo{
        width: calc(20% - 20px);
    }
}

@media only screen and (min-width: 1900px) {
  .offer-item-left-container {
    width: 85%;
    padding-right: 20px;
  }
  .offer-item-right-container {
    width: 15%;
  }
}

@media only screen and (min-width: 1366px) {
  .offer-item-left-container {
    width: 80%;
  }
  .offer-item-right-container {
    width: 20%;
  }

  .edit-form .within-input {
    width: 85%;
  }

}

@media only screen and (max-width: 1200px) {

  .form-group.col-lg-6.no-padding-left,
  .form-group.col-lg-6.no-padding-right {
    padding: 0;
  }
}

@media only screen and (max-width: 1199px) {

  .form-group.col-lg-6.no-padding-left,
  .form-group.col-lg-6.no-padding-right {
    padding: 0;
  }

  .no-padding-max-lg {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 991px) {
  .offer-item-head {

    .offer-item-left-container {
      border: 0;
    }

    .offer-item-total {
      text-align: center;
      padding-bottom: 20px;

      .offer-item-text-left {
        text-align: center;
      }
      .offer-item-text-right {
        text-align: right;
      }

      h4 {
        margin-bottom: 5px;
        border-bottom: 1px solid rgba($light_grey_color, 0.3);

        span {
          padding-left: 0;
        }
      }
      h5 {
        /*line-height: 1.4em;*/

        &.offer-item-special-padding {
          padding-top: 6px;
        }
      }

      span {
        border: 0;
      }

      /*            text-align: center;

      h5{
      width: 50%;
      display: inline-block;
      float: left;

      &:nth-last-of-type(odd){
      text-align: left;
    }
    &:nth-last-of-type(even){
    text-align: right;
    padding-right: 5px;
  }
}*/
    }

    .offer-item-activity {
      position: relative;
      right: 0;
      margin-bottom: 20px;
    }
  }

  .user.user-menu {
    a {
      min-height: 50px;

      img.user-image {
        margin-right: 0;
      }
    }
  }

  .account {
    .widget-user {
      .user_avatar {
        float: left;
      }
      .widget-user-header {
        float: left;
        text-align: left;
      }
    }

  }

  .box-header {
    .group_data {
      border-bottom: 0;

      span {
        font-size: .8em;
        padding: 5px;
      }
    }
  }

  .box.list-item-box .box-header.with-border .box-title {
    /*padding-bottom: 0;*/
    border-bottom: 0;
  }

  .widget_action.hidden-md.hidden-lg {
    position: absolute;
    right: 0;
  }

  .local_rent_point {

    span:nth-of-type(2) {
      width: inherit;
      font-size: 1.2em;
    }
  }

  .offer-item-local-rent,
  .offer-item-ab {
    .offer-item-total .col-xs-12.no-padding-right {
      padding-left: 0 !important;
    }
  }
  .offer-item-local-rent {
    .book_button_container {
      padding: 0;
    }
  }

  .search-list-local .offer-item-right-container .offer-item-activity{
      .offer-item-text-right{
          text-align: center;
      }
  }
  
  .search-list-ab{
      .offer-item-total{
          text-align: center;
      }
      
      .orange_color{
          span{
              display: inline-block;
              width: 100%;
              padding-bottom: 6px;
          }
      }
  }
  
  .item-details-modal-container{
      .details .details_images .list-item-photo{
          width: calc(50% - 20px);
      }
      
    .widget .widget_avatar .avatar {
        float: left;
    }
    
    .widget .widget_avatar .widget_type_information{
        width: initial;
    }
      
  }
   
}

@media only screen and (max-width: 767px) {

  .no-padding-xs {
    padding: 0 !important;

    .within-unit {
      float: left;
      padding-left: 20px;
    }

    .within-input {
      width: 80%;
    }

  }

  .within-col {
    margin-top: 15px;
  }

  .offer-item-head {

    .review_added_time {
      display: inline-block;
      width: 100%;
    }

    .offer-item-total {

      .offer-item-text-left {
        text-align: center;
      }
      .offer-item-text-right {
        text-align: center;
      }
      h5 {
        /*line-height: 1.4em;*/

        &.offer-item-special-padding {
          padding-top: 0;
        }
      }
    }
  }
  .form-group.col-sm-6.no-padding-right,
  .form-group.col-sm-6.no-padding-left {
    padding: 0;
  }

  .user.user-menu {
    a {
      padding: 2px !important;

      img.user-image {
        margin-top: 0;
      }
    }
  }

  .calendar-modal .modal-container.transport_activity_modal .box-title {
    margin-top: 10px;

  }
  .calendar-modal .modal-container.transport_activity_modal .fc-toolbar .fc-right {
    float: left;
  }

  .box.list-item-box .box-header.with-border {
    h3.box-title {
      width: 100%;
      border-bottom: 0;
      padding-bottom: 8px;
    }
    .group_data.pull-right {
      float: left !important;
      margin-right: 2px;
      margin-bottom: 3px;
      margin-left: 0;

      span {
        font-size: .8em;
        padding: 5px;
      }
    }
  }

  .offer-item-local-rent {
    .widget_avatar_container,
    .widget_type_information,
    .local_rent_point {
      text-align: center;
    }
    .offer-item-total .col-xs-12.no-padding-right {
      padding-left: 0 !important;
    }
    .widget_type_information {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }

  .offer-item-ab {
    .widget_avatar {
      text-align: center;

      .widget_title,
      .widget_type_information,
      {
        margin-left: 0;
      }

    }
    .avatar.circle {
      margin: 0 auto;
    }

    .widget .widget_avatar .avatar {
      float: none;

      & + div {
        float: none;
      }

    }

  }

  .offer-item-local-rent,
  .offer-item-ab {
    .widget_action .group_data:first-of-type {
      margin-left: 0;
    }
  }

  .offer-item-local-rent .widget_title {
    text-align: center;
  }

  .widget_avatar_container {
    text-align: center;
  }

  .transport-add-form,
  .transport-edit-form {

    .tooltip-inner {
      position: absolute;
      top: -10px;
    }
  }

  .profile_fulfillment .profile_data {
    padding: 5px;
    border-right: 0;
    h4 {
      text-align: center;
    }
  }
  
  .search-list-local .offer-item-right-container .offer-item-activity{
      .offer-item-text-right{
          text-align: center;
      }
  }

  .search-local-widget-container{
      text-align: center;
  }
  
  .types-of-transports{
      text-align: center;
  }
  
  .types-of-transports-ab{
      .lp_header{
          margin-top: 20px;
      }
  }
  
}

@media only screen and (max-width: 640px) {

  .course_points_table_container {
    overflow-y: scroll;
    width: 100%;

    #course_points_table {
      min-width: 500px;
    }
  }

  .timetable_table {
    overflow-y: scroll;
  }
  
  .item-description-content{       
      
      table{
          min-width: 450px;
      }
  }
  
  .table-transport-overflow{
         overflow-x: auto; 
      }  

}

@media only screen and (max-width: 540px) {

  .account {
    .widget-user {
      .user_avatar {
        float: initial;
      }
      .widget-user-header {
        float: left;
        text-align: center;
      }
    }
    .button.button--sacnite {
      position: absolute;
      right: 0;
      top: 10px;
    }
  }

  .user_reviews .reviews .single_review .review_count {
    font-size: 2em;
  }

}

@media only screen and (max-width: 480px) {
  .item-additional-info-container {

    label {
      margin-right: 0;
      width: 100%;
    }
  }

  .profile_fulfillment .profile_data {
    padding: 5px;
    /*text-align: left;*/
  }

  .booking-transport-checkbox {
    position: relative;
    right: initial;
    top: initial;
    -webkit-transform: none;
    transform: none;

    // label {
    //   .icheckbox_square-orange {
    //     margin-left: 5px;
    //   }
    // }
  }
  
  .booking_history_filter_div{
        margin-bottom: 5px;
        
        span{
            min-width: 50px;
            display: inline-block;
        }
    }
    
    .box-body{
        padding: 0px;
    }

}


/*Remove the border radius from user avatar*/
.navbar-nav > .user-menu .user-image {
  border-radius: 0;
  width: 40px;
  height: 40px;
  margin-top: -10px;
}

/* account / edit account/ Our Advantages */
.account-edit-form {
  margin-top: 5px;
}

#our_advantages_container {

  li.select2-selection__choice {

    color: #fff;
    background-color: $grey_color;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background-color: $light_grey_color;
    }

    span.select2-selection__choice__remove {
      color: #fff;
    }
  }
}

/* fix google autocomplete not show*/
.pac-container.pac-logo {
  z-index: 99999;
}

/* user login/logout*/
/*.skin-blue .main-header li.user-header {
background-color: rgba($light_grey_color, 0.1) ;

p{
color: $grey_color;
}
}
.navbar-nav>.user-menu>.dropdown-menu>li.user-header>p {
color: rgba($grey_color, 0.8);
}

.navbar-nav>.user-menu>.dropdown-menu>li.user-header>img.img-circle {
border-radius: 0;
border: 0;
}*/
.main-header {

  nav {

    #user_menu {

      margin: 0;
      border: 0;
      background-color: $light_orange_color;
      right: 0;

      .user-header {
        background-color: $light_orange_color;
        border: 0;

        img {
          border: 0;

          &.header_avatar_big {
            max-height: 90px;
            width: auto;
          }
          &.header_avatar_small {
            max-height: 25px;
            width: auto;
          }
        }

        p {
          color: #fff;
          /*text-shadow: 1px 1px black;*/

          small {
            margin-top: 5px;
            text-shadow: none;
          }
        }

      }
      .user-footer {
        background-color: #fff;
        /*border: 2px solid rgba($orange_color, 0.5);*/
        border-top: 0;
        border-bottom: 0;
        padding: 0 !important;

        a {
          font-weight: 800;
          width: 100%;
          text-align: center;
          margin: 0;
          color: $light_orange_color;
        }
        a:hover {
          width: 100%;
          text-align: center;
          margin: 0;
          color: #fff;
          background-color: $light_grey_color;
        }

        /*                a.btn.btn-own-dark-orange.btn-flat.text_color_grey{
        color: #fff;
        width: 100%;
        background-color: $light_grey_color;
      }*/

      }
    }
  }
}

.repeating_end_options {
  line-height: 38px;

  .inline-input {
    width: 30%;
    display: inline-block !important;
  }
}

.red-label {
  background-color: #F44336 !important;
}

.green-label {
  background-color: #009688 !important;
}

.grey-label {
  background-color: grey !important;
}

.courses_table {
  i {
    font-size: 1.5em;
    cursor: pointer;
  }

  i:hover {
    color: #ff7100;
  }
}

.courses_table_td {
  line-height: 30px;
  height: 30px;

  img {
    height: 30px;
    float: left;
    padding-left: 15px;
  }

  span {
    line-height: 30px;
    display: block;
    float: left;
    text-align: left;
    padding-left: 15px;
  }
}

.small-modal-container .loader {
  position: absolute !important;
}



.offer-item-transport {
  padding: 5px !important;
}

@media (min-width: 1200px) {
  .offer-item-transport {
    width: 100%;
  }
}

.offer-item-transport:nth-child(odd) {
  background-color: #eee;
}

.list_action .search-item {
  position: absolute;
  right: 20px;
  font-size: 1.1em;

  i:hover {
    color: #ff7100;
  }
}

.center-button {
  display: block;
  margin: 15px auto 0;
  float: none;
  min-width: 120px;
}

.route-table {
  td {
    background: rgba(128, 128, 128, 0.5);
    color: grey;
  }
}

.active-route {
  td {
    background: rgba(228, 96, 39, 0.3);
    color: #000000;
  }
}

.within-input {
  width: 75%;
  float: left;
}

.within-unit {
  float: right;
  display: block;
  height: 34px;
  line-height: 34px;
}

.timetable_table {
  /*overflow-y: scroll;*/
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.result_container .result_list .details {
  .row:last-of-type, {
    border-bottom: 0;
  }
}

#accordion {

  th {
    background: #fff;
  }
  .panel:nth-child(odd) {
    background-color: #eee;
  }
  .panel {
    margin-bottom: 5px;

    .box-header {
      cursor: pointer;

      .offer-type-label {
        padding-bottom: 0px;
      }

      h5 {
        font-size: 0.9em;
        line-height: 22px;
      }
      span.fa {
        opacity: 0.7;
      }
    }
  }
  .panel:hover {
    border: 1px solid grey;

    .box-header {
      h5 {
        font-weight: 900;
      }
    }
  }

  .list_action {
    min-height: 28px
  }

  th, td {
    padding: 2px 0 4px 0 !important;
  }

}

input {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

.pac-container {
  min-width: 300px;
}

/*Fix for profile our adventages (no result found)*/
.select2-hidden-dropdown {
  display: none !important;
}

/*Fix for tooltips in transport edit form*/
.tooltip {
  z-index: 9999 !important;
}

/*Fix for black scroll bar in cloud information on map*/
.gm-style-iw div div {
  overflow: hidden !important;
  padding: 5px 0;
}

.course-edit {
  width: 50%;
  float: left;
}

.course-edit-button {
  width: 49%;
}
.search-item-photo-container {


  .photocontent {
    width: 100%;
    float: left;
    position: relative;
    margin: .5%;

    &:before {
      content: '';
      padding-top: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .photo {
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      max-height: 240px;
      background-position: center;
    }
  }
}

.funds {

  .orange-label {
    background: $orange_color;
    text-transform: uppercase;
    height: 28px;
    line-height: 28px;
    padding: 0 15px;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      background: #ff8626;
    }
  }
}

.available-funds {
  background: #ff8220;
  color: #fff;
  padding: 10px;
}

#funds_form {

  .amount_input_currency {
    position: relative;

    span {
      position: absolute;
      line-height: 34px;
      right: 50px;
    }

  }
}

.available_soon_info{
  width: 100%;
  height: 100%;
  background: rgba(184, 199, 206, 0.95);
  display: block;
  height: 22px;
  position: absolute;
  top: 0;
  text-align: center;
  opacity: 0.9;
  line-height: 22px;
  padding-right: 10px;
  font-weight: 900;
}

.beta_alert {
  position: fixed;
  margin: 0;
  bottom: 10px;
  right: 10px;
  margin-left: 10px;
  z-index: 999;
  border-radius: 0px;
  text-align: center;
  background: rgba(255, 133, 27, 0.9) !important;
  
  p{
      font-size: 0.9em;
      line-height: 110%;
  }

  button.close {
    padding: 0;
    opacity: 1;
    right:0 !important;
    top: -10px !important;
    transition: all .5s;
    
    &:focus {
       outline: none;
    }
    
    &:hover{
        opacity: .7;       
    }
    
    .fa.fa-times{
        color: #fff;
    }
    
    &.app-blade-close{
        right: -20px !important;
    }
  }
}

#try_again{
    cursor: pointer;
}

.try_again{
    cursor: pointer;
    color: $orange_color;
}

.courseTable {
  @for $i from 1 through 6 {
    &:nth-child(#{$i}) {
      animation-duration: 100ms * $i;
      animation-delay: 100ms * $i - 100;
      animation-fill-mode: forwards;
    }
  }
}

.courseTablePanel {
  @for $i from 1 through 11 {
    &:nth-child(#{$i}) {
      animation-duration: 50ms * $i;
      animation-delay: 50ms * $i - 50;
      animation-fill-mode: forwards;
    }
  }
}

#transport-select .select2-selection__rendered li {
  padding-top: 1px;
}

#transport-select li.select2-selection__choice  {
  height: 32px;
}
#transport-select span.select2-selection__choice__remove {
  vertical-align: top !important;
}

.transport-select-image {
  max-width:30px;
  max-height: 23px;
  margin-top: 3px;
}

.transport-select-text {
  padding-top: 8px;
  padding-left: 2px;
}

.transport-avatar {
  img {
    max-width: 60px;
    width: 100%;
    height: 60px;
    overflow: hidden;
    position: relative;
    float: right;
    margin-right: 5px;
    border-radius: 50%;
    background-color: rgba($grey_color, 0.2);
  }
}

.location_transports {
  display: grid;
  grid-template-columns: 75% auto;
}

.showBookingInformation {
  overflow: hidden;
}

.priceSearchTitle {
  text-align: right;
  padding-right: 5px;
  align-self: center;
  font-weight: bold;
}

.priceSearch {
  display: grid;
}



.grid-container {
  display: flex;
  grid-column-gap: 5px;
  /*grid-auto-columns: 200px;*/
  /*grid-template-columns: auto auto auto;*/
  padding: 5px;
  grid-template-columns: 380px repeat(auto-fill, 380px);
  overflow: auto;
  resize: both;
  flex-wrap: wrap;
  justify-content: center;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  margin-bottom: 5px;
  /*font-size: 30px;*/
  /*text-align: center;*/
  //max-width: 380px;
  // flex: min-content;
  flex-basis: 23em;
  // min-width: 330px;
}

.orange {
  color: #ff7100;
}

.iti-mobile .intl-tel-input.iti-container {
  position: fixed;
  z-index: 9999;


}

.intl-tel-input.iti-container {
  left: 0px !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.agreement-small-text {
  font-size: smaller;
}

.agreement-checkbox {
  padding-right: 5px;
}

.insurance div.icheckbox_square-orange {
  margin-right: 10px;
}
.insurance {
  font-size: large;
}


.insurance-headline {
  font-size: x-large;
  color: #ff851b;
  text-align: center;
}

.color-red {
  color: red;
}

#reportsBookingHistory {
  margin-top: 4rem;
}

.add-button {
  font-weight: 900;
  font-size: larger;
}

.x-large-size {
  font-size: x-large !important;
}

.flex-transport-images {
  flex: auto;
  display: flex;
  flex-wrap: wrap;
}

.flex-transport-images .image-container {
  flex: auto;
  min-width: 300px;
  height: 200px;
}

.flex-transport-images .image-container .list-item-photo {
  height: 100% !important;
  width: 100% !important;
}


@import "_booking";
@import "_reviews";
@import "_mapstyles";
@import "_reports";
@import "_admin";
@import "_faq";
