.users-table {
  tbody > tr > td {
    line-height: 30px;
  }
}

.button--sacnite__typesArray {
  margin: 0 auto !important;
  display: block;
  float: none;
}

.widget-user-image__center {
  margin: 0 auto;
}

.widget-user-image__center__lang {
  max-width: 40px;
  max-height: 40px;
}

.main-footer {
  border-top: none;
}

.edit-form-type, .edit-form-additional {
  .form-group {

    label {
      width: 100%;
      text-align: left;
    }
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-weight: 700;
    color: white;
    background-color: #58585a;
    display: inline-block;
    cursor: pointer;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    background-color: #f26522;
  }
}