.offerShortView {
    z-index: 11;
    background: rgb(255, 255, 255);
    box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.3), -1px -1px 1px 0px rgba(0,0,0,0.3);
    position: absolute;
    top: 10px;
    right: 5px;
    width: 300px;
    /*max-width: 400px;*/
    /*min-width: 250px;*/
    height: 450px;
    overflow: hidden;

    .fa.fa-times{
        cursor: pointer;
    }
    .see_more_details{
        cursor: pointer;
        text-align: center;

        position: absolute;
        bottom: 50px;
        width: 100%;

        a{
            color: #666;
            font-size: .85em;
            margin-top: 10px;
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
            padding: 5px 20px;
            color: $orange_color;
            border-bottom: 1px solid $light_orange_color;

            &:hover{
                background-color: $orange_color;
                color: #fff;
            }
        }
    }

    .offer_view_slider{

        .slick-slide{
            &:focus{
                outline:0
            }
        }

        .offer_view_title{

            font-size: 1.1em;
            font-weight: 600;
            padding: 10px 25px 10px 10px;

            i{
                position: absolute;
                top: 5px;
                right: 7px;
                -webkit-transition: all .5s;
                transition: all .5s;

                &:hover{
                    color: #666;
                }

            }
        }
        .offer_view_place{
            font-size: .9em;
            padding: 5px 0  0 10px ;

            span:nth-of-type(2){
                font-weight: 600;
            }
        }

        .offer_view_price{
            font-size: .8em;
            padding: 5px 0  0 10px ;

            span:nth-of-type(2),{
                font-weight: 600;
            }
            span:nth-of-type(3){
                font-weight: 600;
            }
        }

        .single-offer-review{
            padding: 0 5px;
            display: inline-block;

            h5{
                width: 100%;
                line-height: 1em;
            }

            i{
                color: $orange_color;
            }

            .star_reviews{
                font-size: 0.9em;
                line-height: 0.9em;
                padding-left: 5px;
                margin-top:5px;

                &:before{
                    font-size: 0.9em;
                    line-height: 0.9em;
                }
            }
            .text{
                font-size: 0.9em;
                line-height: 0.9em;
                padding-left: 5px;
                margin-top:5px;
            }

        }

        .offer_guests_number{
            font-size: .8em;
            padding: 3px 0  0 10px ;
            line-height: .8em;

            span{
                font-weight: 600;
            }
        }

        .slick-prev.slick-arrow,
        .slick-next.slick-arrow{
            bottom: 0;
            position: absolute;
            z-index: 99;
            width: 50%;
            padding: 5px 0 !important;
            color: $grey_color;
            background-color: transparent;
            border: none;
            text-align: center;
            height: 2em;
            -webkit-transition: all .5s;
            transition: all .5s;

            &:focus{
                outline:0
            }

            &:hover{
                color: #fff;
                background-color: $grey_color;

            }

            i{
                position: absolute;
                left: 50%;
                font-size: 1.6em;
                margin-bottom: 10px;
            }

        }

        .slick-prev.slick-arrow{
            left: 0;

            i{
                left: 50%;
            }

        }
        .slick-next.slick-arrow{
            right: 0;

            i{
                right: 50%;
            }

        }


    }

    .offer_images_slider{

        max-height: 60%;

        .offer_image_slide{
            height: 45%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            &.no-images{
                height: 35%;
                text-align: center;
                color: #666;
                display:table;

                .no-images-center{
                    display: table-cell;
                    vertical-align: middle;

                    p:first-of-type{
                        margin-bottom: 10px;
                    }
                }


                i{
                    font-size: 5em;
                    color: #666;
                }

            }
        }

        .slick-dots{
            position: absolute;
            bottom: 5px;
            left: 50%;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);


            li{
                float: left;
                margin: 0 2px;

                /*opacity:0;*/

                &.slick-active{

                    button{
                        background-color: #fff;
                    }
                }

                button{
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    background-color: transparent;
                    color: transparent;
                    border: 2px solid #fff;
                    border-color: #fff;
                    padding: 0px !important;

                    &:focus{
                        outline:0
                    }
                }

            }
        }

    }

    .offer_overlay{
        position: absolute;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 100%;
        z-index: 999;
    }

    .offer_loader {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        /*transform: translate(-50%,-50%);*/
        border: 5px solid #f3f3f3; /* Light grey */
        border-top: 5px solid $orange_color; /* Blue */
        border-radius: 50%;
        width: 40px;
        height: 40px;
        -webkit-animation: spin2 2s linear infinite;
                animation: spin2 2s linear infinite;
    }

    @-webkit-keyframes spin2 {
        0% { -webkit-transform: translate(-50%,-50%) rotate(0deg) ; transform: translate(-50%,-50%) rotate(0deg) ; }
        100% { -webkit-transform: translate(-50%,-50%) rotate(360deg); transform: translate(-50%,-50%) rotate(360deg); }
    }

    @keyframes spin2 {
        0% { -webkit-transform: translate(-50%,-50%) rotate(0deg) ; transform: translate(-50%,-50%) rotate(0deg) ; }
        100% { -webkit-transform: translate(-50%,-50%) rotate(360deg); transform: translate(-50%,-50%) rotate(360deg); }
    }
}

.triangle-top-2,
.triangle-bottom-2{
    -webkit-transition: all .2s;
    transition: all .2s;
}

.offer-item-local-rent{
    -webkit-transition: all .2s;
    transition: all .2s;
}

.offer_details_active{
    background-color: #eee;

    .triangle-top-2{
        border-color: transparent transparent #eee transparent
    }
    .triangle-bottom-2{
        border-color: #eee transparent transparent transparent;
    }
}

.offerFastPreview {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 12;

  .offer_fast_preview {
    padding: 5px;

    .singleOffer {
      border-bottom: 1px solid grey;
      padding-bottom: 5px;
      margin-bottom: 5px;
      font-size: 0.8em;
    }
  }
}
