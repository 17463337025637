/*
A file with a variable, which are using in the other Scss files.
*/
/*
    Created on : 2015-12-29, 15:15:01
    Author     : Crisss
    Contact    : cris@lifeinmobile.com
*/
/* COLORS:*/
/* example:*/
/* $main-color rgb(0,0,0) itd.*/
/* BACKGROUND-COLORS:*/
/* example:*/
/* $main-bg-color rgb(0,0,0) itd.*/
/*BUTTONS-COLORS*/
/* example:*/
/* $main-btn-color rgb(0,0,0) itd.*/
/* FONTS*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:200,400,600,900&subset=latin-ext);

/* $Roboto-normal: 400 0.8rem Roboto, sans-serif;*/
/* $Roboto-normal-italic: italic 400 0.8rem Roboto, sans-serif;*/
/* $Roboto-bold: 900 0.8rem Roboto, sans-serif;*/

/*
  STANDARD FONT
*/

$standard_font_family: 'Open Sans', 'Tahoma', sans-serif;
$standard_font_size: 16px;

/*
  STANDARD COLOR
*/
$grey_color: #424C50;
/* $orange_color: #ff9300;*/
$orange_color: #ff7100;
$light_grey_color: lighten($grey_color, 20);
$light_orange_color: lighten($orange_color, 20);

/* .clearfix {*/
/*   display: table;*/
/*   clear: both;*/
/*   width: 100%;*/
/* }*/

strong {
  font-weight: 600;
  display: inline-block;
}

/*
  MIXINS
  @color: (color, opactiy);
  @background: (color, opacity);
  @border-radius: (radius);
*/
@mixin color($color, $opacity) {
  color: rgba($color, $opacity);
}

@mixin background($color, $opacity) {
  background-color: rgba($color, $opacity);
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin border($position, $size, $type, $color, $opacity) {

  @if $position == "top" {
    border-top: $size $type rgba($color, $opacity);
  } @else if $position == "bottom" {
    border-bottom: $size $type rgba($color, $opacity);
  } @else if $position == "left" {
    border-left: $size $type rgba($color, $opacity);
  } @else if $position == "right" {
    border-right: $size $type rgba($color, $opacity);
  } @else {
    border: $size $type rgba($color, $opacity);
  }

}

@mixin transition-duration($time) {
  -webkit-transition-duration: $time;
  transition-duration: $time;
}

.border-test {
  @include border(top, 1px, solid, $orange_color, 1);
  @include border(bottom, 1px, solid, $grey_color, .6);
}

.label_required {
  font-weight: 600;
}

/* OUTLINE DISABLED*/
.selected-flag:focus {
  outline: 0;
}

/*

GLOBAL CLASSES
@padding;
@radius;

*/
/* PADDING */
.no-padding, .p0 {
  padding: 0 !important;
}

.no-left-padding, .pl0 {
  padding-left: 0 !important;
}

.no-right-padding, .pr0 {
  padding-right: 0 !important;
}

.no-top-padding, .pt0 {
  padding-top: 0 !important;
}

.no-bottom-padding, .pb0 {
  padding-bottom: 0 !important;
}

/* MARGIN*/
.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.m15 {
  margin: 15px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

// TEXT ALIGNMENT
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}

.no-radius {
  border-radius: 0;
}

.white_color {
  color: white;
}

.grey_color {
  color: $grey_color;
}

.orange_color {
  color: $orange_color;
}

.grey_background {
  background-color: $grey_color;
}

.orange_background {
  background-color: $orange_color;
}

.grey_hover_color {
  @include transition-duration(.6s);
  &:hover {
    color: $grey_color;
    @include transition-duration(.6s);
  }
}

.orange_hover_color {
  @include transition-duration(.6s);
  &:hover {
    color: $orange_color;
    @include transition-duration(.6s);
  }
}

.grey_hover_background {
  @include transition-duration(.6s);
  &:hover {
    background-color: $grey_color;
    @include transition-duration(.6s);
  }
}

.orange_hover_background {
  @include transition-duration(.6s);
  &:hover {
    background-color: $orange_color;
    @include transition-duration(.6s);
  }
}

/*
  DEFAULT BUTTON STYLES
*/
.default_button {

  font-family: $standard_font_family;
  font-size: $standard_font_size - 3;
  font-weight: 600;
  text-transform: uppercase;
  margin: 5px 0;
  padding: 15px;
  cursor: pointer;
  @include transition-duration(.6s);
  box-shadow: none;
  border-style: solid;
  border-width: 0;
  border-bottom: 1px solid #000;
  @include border-radius(0);

  &.small {
    padding: 9px 0;
  }

  &.full_width {
    width: 100%;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    @include transition-duration(.6s);
  }

}

/*
  GREY BUTTON
*/
.default_grey_button {

  @extend .default_button;
  border-color: rgba($light_grey_color, .3);
  -webkit-transition: all .5s ease-out;
  transition: all .5s ease-out;
  background: transparent;
  color: $grey_color;

  &:hover {

    background-color: $light_grey_color;
    border-color: $light_grey_color;
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    @include color(#fff, 1);

  }

}

/*
  ORANGE BUTTON
*/
.default_orange_button {

  @extend .default_button;
  border-color: rgba($light_orange_color, .3);
  color: $orange_color;
  -webkit-transition: all .5s ease-out;
  transition: all .5s ease-out;
  background: transparent;

  &:hover {

    background: $light_orange_color;
    border-color: $light_orange_color;
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    @include color(#fff, 1);

  }

}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  animation: #{$str};
}

/*/ Mixin to customize scrollbars*/
/*/ Beware, this does not work in all browsers*/
/*/ @author Hugo Giraudel*/
/*/ @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width*/
/*/ @param {Color} $foreground-color - Scrollbar's color*/
/*/ @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color*/
/*/ @example scss - Scrollbar styling*/
/*/   @include scrollbars(.5em, slategray);*/
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 5%)) {
  /* For Google Chrome*/
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }

  /* For Internet Explorer*/
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
