.reports {

  #chart {
    height: 350px !important;
    margin-top: 5px;
  }

  .category-list {
    position: relative;

    ul {
      position: absolute;
      z-index: 999;
      background: #fff;
      right: 0;
      padding: 10px;
      box-shadow: 1px 1px 3px lightgrey, -1px -1px 3px lightgrey;

      li {
        width: 100%;
        cursor: pointer;
        display: block;

        span {
          display: block;
          text-align: center;
          text-transform: uppercase;

          &:hover {
            background: $light_orange_color;
          }

          &.active {
            background: $light_orange_color;
          }
        }
      }
    }
  }

  .view-options {
    height: 44px;

    .time-period {
      position: relative;
      left: 50%;
      -webkit-transform: translate(-50%);
      transform: translate(-50%);
      width: 100%;

      .periods, .arrow {
        display: block;
        float: left;

        .showed {
          opacity: 1;
          -webkit-transition: all .5s ease;
          transition: all .5s ease;
          -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
          -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
        }

        div {
          opacity: 0;
          -webkit-transition: all .5s ease;
          transition: all .5s ease;
          -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
          height: 44px;
          line-height: 44px;
          position: absolute;
          width: calc(100% - 80px);
          left: 40px;
          text-align: center;
        }
      }

      .arrow {
        line-height: 44px;
        height: 44px;
        width: 40px;
        text-align: center;
        font-size: 1.5em;
        cursor: pointer;

        &:hover {
          background: $light_orange_color;
        }
        &:last-child {
          float: right;
        }
      }
    }

    .buttons {
      float: right;
      width: 100%;
      text-align: center;

      button {
        margin: 0;
      }
    }
  }

  .active-category {
    height: 45px;
    margin-bottom: 10px;

    span {
      display: block;
      text-align: center;
      width: calc(100% - 60px);
      cursor: default;

      &:hover {
        background: #fff;
        color: #ff7100;
      }
    }

  }

  .dropdown-btn {
    width: 40px;
    height: 25px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  .dropdown-btn span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: #ff7100;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  .dropdown-btn span:nth-child(1) {
    top: 0px;
  }

  .dropdown-btn span:nth-child(2) {
    top: 12px;
  }

  .dropdown-btn span:nth-child(3) {
    top: 24px;
  }

  .dropdown-btn.open span:nth-child(1) {
    top: 12px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .dropdown-btn.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .dropdown-btn.open span:nth-child(3) {
    top: 12px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

}

.reports_fulfillment {
  border: 2px solid #fa6;

  .box-header {
    padding-bottom: 0;
  }

  .description-block {
    margin: 0px;

    .description-text {
      text-transform: none;
    }

    .description-header {
      font-size: 20px;
      line-height: 32px;
      background: #ff7100;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: #fff;
      margin: 5px auto;
    }
  }

  .part:not(:last-child) {
    border-right: 1px solid #bdbdbd;
  }
}

.chart-container-outer {
  margin-top: 10px;
  position: relative;
}

.chart-container-center {
  max-width: 100%;
  margin: 0 auto;
}


.chart-container-traveler {
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .5), -1px -1px 1px rgba(0, 0, 0, .3);

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 2em;
  }

  h2 {
    font-size: .9em;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }

  .single-chart-traveler {
    margin-top: 20px;
  }
}

.traveler-reports-heading {
  font-size: 1.5em;
}

.total_chart_container {
  /*margin: 20px 0;*/
}

.offerer-chart-container{
    position: relative;
    
    .no-charts-to-display{
        z-index: 99;
        margin-top: 5px;
    }
}

.no-charts-to-display {
  /*margin-top: -10px;*/
  width: 100%;
  position: absolute;
  height: calc(100% + 20px);
  background: rgba(51, 51, 51, 0.9);

  h4 {
    display: block;
    color: white;
    text-align: center;
    margin-top: 50px;
    font-size: 1.3em;
    line-height: 130%;
  }
}

.total_chart_single {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .reports {
    .view-options {

      .time-period {
        width: 50%;
      }
    }
  }
}

@media (min-width: 992px) {
  .reports {
    .view-options {

      .time-period {
        position: absolute;
        width: 25%;
      }

      .buttons {
        float: right;
        width: auto;
        text-align: none;

        button {
          margin: 0;
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .chart-container-center {
    max-width: 80%;
  }
}

@media (max-width: 991px) {
    .no-charts-to-display {
        margin-top: 10px;
    }
    
    .chart-container-outer{
        margin-top: -10px;
    }

    .reports {
        .view-options{
            height: 100px;
        }
        .active-category{
            
            height: 60px;
            
            span{
                width: 100%;
            }
        } 
    }
}

@media (max-width: 768px) {
  .reports_fulfillment .part:not(:last-child) {
    border-right: none;
  }
}
