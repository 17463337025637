.booking_title {
    font-size: 1.5em;
    line-height: 1.5rem;
    float: left;
    @media (max-width: 600px) {
        text-align: center;
        float: none;
    }
}

.offer-item-left-container {
    .widget_avatar {
        display: inline-block;
        float: none;
        vertical-align: middle;
        width: 60px;
        & + .booking_title {
            display: inline-block;
            float: none;
            vertical-align: middle;
            padding-left: 20px;
        }
    }
}

.payment_advance_info {
  padding: 15px;
  background: #ffe6d1;
  font-size: 0.9em;
  text-align: center;

  &.mb {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}


.booking_status {
    font-size: 1.1em;
    line-height: 1.1em;
    display: block;
    float: right;
    @media (max-width: 600px) {
        text-align: center;
        float: none;
    }
    .label {
        vertical-align: middle;
        position: relative;
        @media (min-width: 601px) {
            top: -2px;
        }
    }
}

.courses_table {
    th {
        background-color: $orange_color;
        color: #fff;
        font-weight: 600;
    }
    th, td {
        padding: 3px !important;
    }
}

.local_booking_title {
    font-weight: 600;
    display: block;
    width: 100%;
    font-size: 1.5em;
    line-height: 120%;
    padding-left: 0 !important;
}
.local_booking_subtitle {
    font-size: 1.2em;
    line-height: 120%;
    padding-left: 0 !important;
}

.offer-item-container {
    padding: 20px 0;
    @media (min-width: 992px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; -js-display: flex;;
        -webkit-box-pack: center;;
            -ms-flex-pack: center;;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }
    .avatar {
        margin-top: 0 !important;
    }
}

.price-summary {
  text-align: right;
  min-height: 55px;
  line-height: 35px;
  background: #ffe6d1;

  h3 {
    /*background: #ffe6d1;*/
    padding-left: 15px;
    font-weight: 900;
    
    &.ab_summary{
        line-height: 55px;
    }
  }
  
  h4{
      line-height: 120%;
      font-size: .9em;
      
      &.price_details_title{
          line-height: 200%;
          font-weight: 600;
          font-size: 1em;
      }
      
      span{
          font-weight: 600;
          display: inline-block;
          min-width: 35px;
          
          &.bigger_span_width{
              min-width: 42px;
          }
      }
  }
  
  .total_price_details{
      line-height: 110%;
      font-size: .85em;      
  }
  
}

.booking_details{

    .traveler_information{

        .lp_header{
            /*font-size: 1.5em;*/
        }

        h4{
            font-size: 16px;
        }

        p{
            display: block;
        }

        .offer-item-employee-detail h4{
            margin-bottom: 0;
        }
    }

    .booking_detail_info{

        .offer-item-employee-detail{
            float: left;
        }

        p{
            display: block;
        }
    }

    #booking-page-all{
        .offer-item-left-container {
            width: 100%;
        }
    }

}

@media (max-width:991px){
    .booking_details_head{
        text-align: center;
    }

    .booking_status{
        margin-top: 5px;
    }
}

.additional_information_for_client {
  margin-top: 15px;
}
